import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../components/contexts/LanguageContext';
function BreadCrumb(props) {
  const { language, changeLanguage , isGeorgian, content } = useContext(LanguageContext);
  const contentPage = <>
                        {props.parent[1] ? <li><Link to={`/${language}/content/${props.parent[1]?.href.includes('m=') ? props.parent[1]?.cat_id : props.parent[1]?.href}`}>{isGeorgian ? props?.parent[1]?.name_geo : props?.parent[1]?.name_eng} </Link></li> : ""}
                        {props.itself[1] ? <li><Link to={`/${language}/content/${props?.itself[1].cat_id}`}>{isGeorgian ? props?.itself[1]?.name_geo : props?.itself[1]?.name_eng} </Link></li> : ""}
                      </>

  const contactBreadCrumb = <li><Link>{props.pageName} </Link></li>
  return (
    <div className='breadcrumb'>
      <div className="container">
        <ul className="d-flex align-items-center h-100 gap-2 p-0">
          <li><Link to={`/${language}`}>{content.main} </Link></li>
         {props.isContentPage ? contentPage : contactBreadCrumb}
        </ul>
      </div>
    </div>
  )
}

export default BreadCrumb