import React, { useState, useRef, useEffect, useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import { LanguageContext } from '../../components/contexts/LanguageContext';
function FooterMenuAccord(props) {
    // console.log(props)
    const { language, changeLanguage, isGeorgian } = useContext(LanguageContext);
    const emptyBodyRef = useRef([]);

    console.log(props)
    useEffect(() => {
        // Check if accordion body has no children
        emptyBodyRef.current.forEach((item) => {

            if (!item.childNodes[1].querySelector('.accordion__body--link')) {
                // Add your desired class to the accordion-item
                item.classList.add('no-body');
                item.classList.add('d-none');
            }
        });
    },);

    //console.log(props.innerMenu)


    return (
        <div style={{ width: '317px', flexShrink: '0' }} className={props.contentId == 75 ? 'd-none' : ""}>
            <Accordion defaultActiveKey={props.itself[1]?.parent_id || props.itself[1]?.cat_id || '0'} alwaysOpen>
                {props.entireMenu.filter(item => item[1].level == 1).map((menu, i) => {
                    // Determine if the current item or any of its children are active
                    const isParentOrSelfActive =
                        menu[1].cat_id == props.itself[1]?.parent_id ||
                        menu[1].cat_id == props.itself[1]?.cat_id;

                    return (
                        <Accordion.Item
                            eventKey={isParentOrSelfActive ? '0' : i + 1}
                            ref={(el) => (emptyBodyRef.current[i] = el)}
                            key={menu[0]}
                        >
                            <Accordion.Header>
                                <Link
                                    to={menu[1].cat_id === '252' ? `/${language}/NMSW` :`/${language}/fcontent/${menu[1].cat_id}`}
                                    className={menu[1].cat_id == props.itself[1]?.cat_id ? 'accordion--active' : ""}
                                >
                                    {isGeorgian ? menu[1].name_geo : menu[1].name_eng}
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                                {props.entireMenu.map((qveMenu) => {
                                    if (menu[1].cat_id == qveMenu[1].parent_id) {
                                        return (
                                            <Link
                                                className={`accordion__body--link ${qveMenu[1].cat_id == props.innerMenu[1]?.cat_id ? 'accordion--active' : ""}`}
                                                to={`/${language}/fcontent/${qveMenu[1].cat_id}`}
                                                key={qveMenu[0]}
                                            >
                                                {isGeorgian ? qveMenu[1].name_geo : qveMenu[1].name_eng}
                                            </Link>
                                        );
                                    }
                                    return null;
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
            </Accordion>
        </div>
    );




}

export default FooterMenuAccord