import React, { createContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import contentKA from '../../lang/ka.json'
import contentEN from '../../lang/en.json'
const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'ka');
  const [isGeorgian, setIsGeorgian] = useState(true)
  const [content, setContent] = useState([]);
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);

    const pathSegments = window.location.pathname.split('/');
    pathSegments[1] = newLanguage;
    const newPath = pathSegments.join('/');
   
    window.location.pathname = newPath;
  
    localStorage.setItem('selectedLanguage', newLanguage);
    if(newLanguage == 'en'){
      setIsGeorgian(false)
    }
   
  };

 

  useEffect(() => {
    if ( window.location.pathname.includes('en')) {
      setLanguage('en');
      setIsGeorgian(false)
    }
     if(window.location.pathname.includes('ka')){
      setLanguage('ka');
      setIsGeorgian(true)
    }
    
  }, [isGeorgian]);

  useEffect(() => {
    // Select the appropriate content based on the language
    const selectedContent = language === 'ka' ? contentKA : contentEN;
    setContent(selectedContent || []);
  }, [language]);
 
 
  return (
    <LanguageContext.Provider value={{ language, changeLanguage , isGeorgian, content}}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };