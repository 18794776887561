import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { LanguageContext } from '../components/contexts/LanguageContext';
import Spinner from 'react-bootstrap/Spinner';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import config from '../config/conf.json'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
function Landing() {
  const { language, changeLanguage, isGeorgian, content } = useContext(LanguageContext);
  const [lastnews, setLastNews] = useState({})
  const [latest3News, setLatest3News] = useState([])
  const [shipCertificateData, setShipCertificateData] = useState('')
  const [projects, setProjects] = useState([])

  const [shipCertificateDataError, setShipCertificateDataError] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {

      let link;
      if (isGeorgian == true) {
        link = `${config.baseAPI}/api/news.php?lang=geo`;
      } else {
        link = `${config.baseAPI}/api/news.php?lang=eng`;
      }
      // console.log(link)
      //  console.log(isGeorgian)
      fetch(link)
        .then((response) => response.json())
        .then((data) => {
          //   console.log(data)
          const lastKey = Object.keys(data.მთავარი).pop();


          setProjects(Object.entries(data.პროექტები))
          setLastNews(data.მთავარი[lastKey])
          // Get all keys from data.main
          const allKeys = Object.keys(data.მთავარი);

          // Find the index of the lastKey in allKeys
          const lastIndex = allKeys.indexOf(lastKey);

          // Get the last three keys
          const lastThreeKeys = allKeys.slice(lastIndex - 3, lastIndex);

          // Access the corresponding elements in data.main
          const lastThreeElements = lastThreeKeys.map(key => data.მთავარი[key]);

          //  console.log(data.პროექტები)
          setLatest3News(lastThreeElements)

        });
    }, 1000); // Replace 1000 with the desired timeout duration in milliseconds
    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [isGeorgian, language])




  const [loader1, setLoader1] = useState(false)
  const [certificateFormData, setCertificateFormData] = useState({
    docnr: '',
    seafarernr: '',

  });

  const handleCertificateChange = (e) => {
    const { name, value } = e.target;
    setCertificateFormData({
      ...certificateFormData,
      [name]: value,
    });
  };

  const handleCertificateSubmit = async (e) => {
    e.preventDefault();
    setLoader1(true)
    const queryString = new URLSearchParams(certificateFormData).toString();
    // console.log(queryString)
    try {
      //docnr=GEO014216&seafarernr=1401007483
      const response = await fetch(`${config.baseAPI}/api/sertificate.php?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {

        const data = await response.json();

        setShipCertificateData(data?.txt_content);

        if (data) {
          if (data.txt_content == '') {
            setShipCertificateDataError(true)
          } else {
            setShipCertificateDataError(false)
          }
        }
      } else {
        // Handle error response
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
    setLoader1(false)
  };



  ///////////////////////

  const [shipRegistrationDataError, setshipRegistrationDataError] = useState(0)

  const [registrationTracking, setRegistrationTracking] = useState('')
  const [registrationCERN, setRegistrationCERN] = useState('')
  const [registrationDate, setregistrationDate] = useState('')
  const [loader2, setLoader2] = useState(false)


  const handleregistrationSubmit = async (e) => {
    e.preventDefault();
    setLoader2(true)
    let formattedDate = '';
    if (registrationDate != '') {
      console.log('inside')
      formattedDate = registrationDate.format().split(['T'])[0]
    }


    try {
      let link = `${config.baseAPI}/api/sertificate.php?action=verify_certificate&tracking=${registrationTracking}&cer_number=${registrationCERN}&date=${formattedDate}`
      // console.log(link)
      const response = await fetch(link, {
        method: 'GET',
        headers: {
          'Accept': 'application/pdf',
        },
      });


      if (response.ok) {
        const data = await response.blob();
        // console.log(data)
        if (data.size == 1) {
          const response2 = await fetch(link, {
            method: 'GET',
            headers: {
              'Accept': 'application/pdf',
            },
          });
          if (response2.ok) {
            const data2 = await response2.json()
            //  console.log(data2)
            setshipRegistrationDataError(data2)
          }




        } else {
          setshipRegistrationDataError(0)
          var fileURL = URL.createObjectURL(data);
          window.open(fileURL);
        }



      } else {
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setLoader2(false)
  };

  const [medicalInput, setMedicalInput] = useState('')
  const [medicalError, setMedicalError] = useState(false)
  const [medicalData, setMedicalData] = useState({})

  const handleMedicalInputChange = (event) => {
    // Get the input value from the event
    const inputValue = event.target.value;

    // Use a regular expression to keep only letters
    const filteredInput = inputValue.replace(/\D/g, '');

    // Update the state with the filtered input
    setMedicalInput(filteredInput);
  };
  const handleMedicineSubmit = async (e) => {
    e.preventDefault();
    setLoader1(true)
    try {
      //docnr=GEO014216&seafarernr=1401007483
      const response = await fetch(`${config.baseAPI}/api/sertificate.php?action=checkSeafarer&seafarerNumber=${medicalInput}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/pdf',
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.error) { setMedicalError(true) } else { setMedicalError(false) }
        //  console.log(data)
        setMedicalData(data)
      } else {
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setLoader1(false)
  };



  const [menu, setMenu] = useState([])
  useEffect(() => {
    const link = `${config.baseAPI}/api/site_menu.php`
    fetch(link)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setMenu(Object.entries(data.menu))
        // console.log('data.menu',data.menu)
        console.log(Object.entries(data.menu), `fmenu`)


        //  console.log('menu', menu)


        //  console.log('object entries', Object.entries(menu))


      });



  }, [])











  return (
    <motion.div
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mt-50 landing">
      <div className="landing--top">
        <div className="container">
          <div className="d-flex justify-content-between gap-20 flex-lg-row flex-column flex-wrap">
            {/* <Link to={'fcontent/252'} className="landing--top--link">
              <span>  {content.DPP}</span>
              <img src={require("../assets/images/arrow-bar-right.svg").default} alt="" />

            </Link>
            <Link to={'fcontent/253'} className="landing--top--link">
              <span>   {content.MLC}</span>
              <img src={require("../assets/images/arrow-bar-right.svg").default} alt="" />

            </Link>
            <Link to={'fcontent/251'} className="landing--top--link">
              <span>     {content.Elib}</span>
              <img src={require("../assets/images/arrow-bar-right.svg").default} alt="" />

            </Link> */}
            {menu ? [...menu].reverse().map((item) => {
              console.log(item)
              if(item[1].level == 1){
                return <Link to={item[0] === '252' ? 'NMSW' : `fcontent/${item[0]}`} className="landing--top--link">
                <span dangerouslySetInnerHTML={{__html: isGeorgian ? item[1].name_geo : item[1].name_eng}}></span>
                <img src={require("../assets/images/arrow-bar-right.svg").default} alt="" />

              </Link>
              }
              
            }) : ""}
          </div>
          <div className="backgroundedLinks">
            <Link className="backgroundedLinks--item" to='content/63'>
              <img src={require('../assets/images/bxs-ship.svg').default} alt="" />
              <p dangerouslySetInnerHTML={{ __html: content.ship_registration }}></p>
            </Link>
            <Link className="backgroundedLinks--item" to='content/100'>
              <img src={require('../assets/images/favoritefile.svg').default} alt="" />
              <p dangerouslySetInnerHTML={{ __html: content.recognized_organisations }}></p>
            </Link>
            <Link className="backgroundedLinks--item" to='content/29'>
              <img src={require('../assets/images/79752.svg').default} alt="" />
              <p dangerouslySetInnerHTML={{ __html: content.certificate_verification }}></p>
            </Link>
            <Link className="backgroundedLinks--item" to='content/254'>
              <img src={require('../assets/images/blur-circular.svg').default} alt="" />
              <p dangerouslySetInnerHTML={{ __html: content.circulars }}></p>
            </Link>
          </div>
          <section className='forms--section'>
            <h1 className="sub-title" dangerouslySetInnerHTML={{ __html: content.crew_certificate_verification }}></h1>
            <div className="d-flex justify-content-between flex-lg-row flex-column">
              <form action="" id='certificate-verification' onSubmit={handleCertificateSubmit}>
                <div className="input--container">
                  <div>   <input type="text" placeholder={content.first_name} name="name" onChange={handleCertificateChange} /></div>
                  <div>   <input type="text" placeholder={content.last_name} name="lname" onChange={handleCertificateChange} /></div>
                  <div className={`required ${shipCertificateDataError ? "error" : ""}`}>   <input type="text" placeholder={content.seafarer_number} name="seafarernr" onChange={handleCertificateChange} /></div>
                  <div className={`required ${shipCertificateDataError ? "error" : ""}`}>  <input type="text" placeholder={content.document_number} name="docnr" onChange={handleCertificateChange} /></div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3 flex-lg-row flex-column">
                  <p><span className='asterisk'>*</span>  <span>{content.required_fields}</span></p>
                  <button className='check--btn' type='submit'>{content.verification}</button>
                </div>
              </form>
              <form action="" id="medical-verification">
                <h3 className="sub-title">{content.medical_certificate_verification}</h3>
                <input
                  type="text"
                  value={medicalInput}
                  onChange={handleMedicalInputChange}
                  className={medicalError ? 'error' : ""}
                />
                <button className='check--btn' onClick={handleMedicineSubmit}>{content.verification}</button>
              </form>
            </div>
            <div className="table-responsive">

              <div className="mt-3">
                {medicalData.FirstName ?
                  <div>
                    <ul>
                      <li>
                        <strong>{content.seafarer_name}:</strong> <span>{isGeorgian ? medicalData?.FirstName : medicalData?.FirstNameEn}</span>
                      </li>
                      <li>
                        <strong>{content.seafarer_surname}:</strong> <span>{isGeorgian ? medicalData?.LastName : medicalData?.LastNameEn}</span>
                      </li>
                      <li>
                        <strong>{content.seafarer_PIN}:</strong> <span>{medicalData?.PrivateNumber}</span>
                      </li>
                      <li>
                        <strong>{content.seafarer_number}:</strong> <span>{medicalData?.SeafarerNumber}</span>
                      </li>
                      <li>
                        <strong className='redy'>{content.blank_number}:</strong> <span>{medicalData?.BlankNumber}</span>
                      </li>
                      <li>
                        <strong>{content.issuer}:</strong> <span>{isGeorgian ? medicalData?.IssuingAuthority : medicalData?.IssuingAuthorityEn}</span>
                      </li>
                      <li>
                        <strong className='redy'>{content.fit_for_duty}:</strong> <span>{medicalData?.IsFitForDuty ? content.yes : content.no}</span>
                      </li>
                      <li>
                        <strong>{content.issue_date}:</strong> <span>{medicalData?.DateOfIssue.split('T')[0]}</span>
                      </li>
                      <li>
                        <strong>{content.expiry_date}:</strong> <span>{medicalData?.ExpiryDate.split('T')[0]}</span>
                      </li>
                    </ul>
                  </div>
                  : ""}
              </div>
              {shipCertificateData ?
                <div className='mt-3' dangerouslySetInnerHTML={{ __html: shipCertificateData.replaceAll('images/verprinter.png', 'assets/images/verprinter.png').replaceAll('images/versave.png', 'assets/images/versave.png') }}>

                </div> : ""}

              <div className="d-flex justify-content-center mt-3" style={{ minHeight: '35px' }}>
                {loader1 ? <Spinner animation="grow" /> : ""}
              </div>

            </div>
            <h2 className="sub-title mb-30 mt-2">{content.ship_verification}</h2>

            {shipRegistrationDataError == 1 ? <div className='my-2 redy'>გთხოვთ მიუთითოთ IMO ნომერი ან გაცემის თარიღი</div> : ""}
            {shipRegistrationDataError == 2 ? <div className='my-2 redy'>მოწმობის უნიკალური ნომერის შეყვანა აუცილებელია</div> : ""}
            {shipRegistrationDataError == 3 ? <div className='my-2 redy'>{content.document_not_found}</div> : ""}
            <form action="" id="ship-registration" style={shipRegistrationDataError ? { borderColor: "#ff2e34" } : { borderColor: '#D2D2D2' }}>
              <div>
                <p >{content.tracking_id}</p>
                <input type="text" onChange={(e) => setRegistrationTracking(e.target.value)} />
              </div>
              <div>
                <p >{content.IMO_number}</p>
                <input type="text" onChange={(e) => setRegistrationCERN(e.target.value)} />
              </div>
              <div>
                <p >{content.issue_date}</p>
                <DemoItem>
                  <DatePicker onChange={setregistrationDate} value={registrationDate} />
                </DemoItem>
              </div>
              <button className='check--btn' onClick={handleregistrationSubmit}>{content.verification}</button>
            </form>
            <div className='mt-3'>
              <p>
                საზღვაო ტრანსპორტის სააგენტოს <b>2024 წლის 1 თებერვლის No: 4/CIRC/FSI</b>  ცირკულარის შესაბამისად, ელექტრონული მოწმობები და დოკუმენტები, რომლებიც გაცემულია საქართველოს დროშის ქვეშ მცურავი გემის მიმართ,   მოწმობაზე  ან დოკუმენტზე დატანილი სწრაფი რეაგირების კოდით (QR-ქვედა, მოწმობის მარჯვენა ნაწილში) და უნიკალური საიდენტიფიკაციო  ნომერით (<b>UTN – MTAcertID-000000</b>  მოწმობის ქვედა, შუა ნაწილში), შეიძლება გადამოწმდეს ქვემოთ მოცემული ვერიფიკაციის გზით.
              </p>

              <div className='d-flex justify-content-center my-3'>
                <p style={{ width: '90%', border: "1px solid #D2D2D2" }}></p>
              </div>
              <p>
                As per Electronic Documents and Certificates CIRC <b>No: 5/CIRC/FSI dated February 16<sup>st</sup> , 2024</b> electronic documents and certificates issued to Georgian flagged vessels with a document or certificate number or that have a Quick Response (QR) Code in the lower right corner and a Unique Tracking Number ( <b>UTN – MTAcertID-000000</b> ) in the middle, may be verified below.


              </p>
            </div>
            <div className="d-flex justify-content-center mt-3" style={{ minHeight: "35px" }}>
              {loader2 ? <Spinner animation="grow" /> : ""}
            </div>
          </section>
          <section className="news--section">
           
            <div className='mb-30 d-flex justify-content-between align-items-center'>
                <h1 className="title ">{content.news}</h1>
                <Link className='big--news__more' to={`news`}>{content.see_all}</Link>
            </div>
            <img src={require('../assets/images/Mask Group 1.png')} alt="hey" className='img-fluid' />
            <div className='news--container'>

              <Link className="big--news" to={`news/${isGeorgian ? lastnews?.geo?.rec_id : lastnews?.eng?.rec_id}`}>
                <div className='d-flex gap-3 justify-content-between align-items-start mb-20'>
                  <h2 className="title  col-lg-9">{isGeorgian ? lastnews?.geo?.title : lastnews?.eng?.title}</h2>
                  <p className="big--news__time"> <span>{isGeorgian ? lastnews?.geo?.date.split(' ')[0] : lastnews?.eng?.date.split(' ')[0]}</span>{isGeorgian ? lastnews?.geo?.date.split(' ').slice(1).join(' ') : lastnews?.eng?.date.split(' ').slice(1).join(' ')}</p>
                </div>
                <p className="big--news__description" dangerouslySetInnerHTML={{ __html: isGeorgian ? lastnews?.geo?.intro : lastnews?.eng?.intro }}>

                </p>
                <p className="big--news__more">{content.learn_more}</p>
              </Link>


              <div className="d-flex gap-3 flex-lg-row flex-column">
                {
                  latest3News.length ? [...latest3News].reverse().map((news) => {
                    return <Link className="small--news" to={`news/${isGeorgian ? news.geo.rec_id : news.eng.rec_id}`}>
                      <p className="small--news__time"> <span>{isGeorgian ? news?.geo?.date.split(' ')[0] : news?.eng?.date.split(' ')[0]}</span>{isGeorgian ? news?.geo?.date.split(' ').slice(1).join(' ') : news?.eng?.date.split(' ').slice(1).join(' ')}</p>
                      <h2 className="title  ">{isGeorgian ? news?.geo?.title : news?.eng?.title}</h2>
                      <p className="small--news__description" dangerouslySetInnerHTML={{ __html: isGeorgian ? news?.geo?.intro : news?.eng?.intro }}>

                      </p>
                      <p className="big--news__more">{content.learn_more}</p>
                    </Link>
                  }) : "loading"
                }


                {/* <Link className="small--news" to={''}>
                  <p className="small--news__time"> <span>30</span>მარტი, 2023</p>
                  <h2 className="title  ">საზღვაო ტრანსპორტის სტრატეგიული განვითარების კონცეფციის საჯარო განხილვა</h2>
                  <p className="small--news__description">
                    საქართველოს მთავრობის 2021-2024 წლების სამთავრობო პროგრამა ითვალისწინებს საქართველოს მთავრობის მიერ საზღვაო ტრანსპორტის სტრატეგიის დამტკიცებას. აღნიშნული დოკუმენტის შემუშავების მიზნით, საწყის ეტაპზე, განისაზღვრა საზღვაო ტრანსპორტის სტრატეგიული განვითარების კონცეფციის შემუშავება და დამტკიცება.
                  </p>
                  <p className="big--news__more">გაიგე მეტი</p>
                </Link> */}
              </div>
              {/* <Link className="all--news">ყველა სიახლე</Link> */}
            </div>
          </section>
          <section className="project--section">
            <h1 className="title text-center">{content.our_projects}</h1>
            <div className="d-flex gap-3 mt-lg-5 mt-3 flex-lg-row flex-column">
              {projects.length ?
                projects.map((pr) => {
                  return <Link className="project--item" to={`projects/${isGeorgian ? pr[1].geo.rec_id : pr[1].eng.rec_id}`}>
                    <img src={`${config.baseAPI}/${isGeorgian ? pr[1].geo.img : pr[1].eng.img}`} alt="" />
                    <div className='project--texts'>
                      <h2 className="title" dangerouslySetInnerHTML={{ __html: isGeorgian ? pr[1].geo.title : pr[1].eng.title }}>

                      </h2>
                      <p className="project--description" dangerouslySetInnerHTML={{ __html: isGeorgian ? pr[1].geo.intro : pr[1].eng.intro }}>

                      </p>
                      <p className='big--news__more'>{content.learn_more}</p>
                    </div>
                  </Link>
                }) : ""}



              {/* <Link className="project--item">
                <img src={require('../assets/images/Rectangle 131.png')} alt="" />
                <div className='project--texts'>
                  <h2 className="title">
                    პროექტის სახელწოდება,
                    გათვლილი ორ ხაზზე
                  </h2>
                  <p className="project--description">
                    შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს ნაწარმის
                  </p>
                  <p className='big--news__more'>გაიგე მეტი</p>
                </div>
              </Link>
               <Link className="project--item">
                <img src={require('../assets/images/Rectangle 131.png')} alt="" />
                <div className='project--texts'>
                  <h2 className="title">
                    პროექტის სახელწოდება,
                    გათვლილი ორ ხაზზე
                  </h2>
                  <p className="project--description">
                    შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს ნაწარმის
                  </p>
                  <p className='big--news__more'>გაიგე მეტი</p>
                </div>
              </Link>  */}




            </div>
          </section>
        </div>
      </div>

    </motion.div>
  )
}

export default Landing