import React from 'react'
import { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { useNavigate, Link, useParams } from 'react-router-dom'
import BreadCrumb from './atoms/BreadCrumb'
import Accord from './atoms/Accord'
import { LanguageContext } from '../components/contexts/LanguageContext';
import config from '../config/conf.json'

import Spinner from 'react-bootstrap/Spinner';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function ContentPageApi() {
    const { language, changeLanguage, isGeorgian, content } = useContext(LanguageContext);
    const navigate = useNavigate();
    const { id } = useParams();
   // console.log(id)
    const [api, setApi] = useState({})

    const [parent, setParent] = useState()
    const [istoriebi, setIstoriebi] = useState([])

    const [exactParent, setExactParent] = useState([])
    const [itself, setItself] = useState([])
    const [entireMenu, setEntireMenu] = useState([])
    useLayoutEffect(() => {
        //  console.log(id)
        const link = `${config.baseAPI}/api/site_menu1.php`;
        fetch(link)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                setEntireMenu(Object.entries(data.menu))
                Object.entries(data.menu).map((item, index) => {
                    if (item[1].cat_id == id) {
                   //     console.log(item[1])
                        setApi(item[1].content_id)
                        //   console.log(item[1])
                        setParent(item[1].parent_id)


                        const apiLink = `${config.baseAPI}/api/get_content.php?content_id=${item[1].content_id[0]}`
                        // console.log(apiLink)
                        fetch(apiLink)
                            .then((res) => res.json())
                            .then((dat) => {
                                //  console.log(dat)
                                setIstoriebi(dat)
                                Object.entries(data.menu).map((qveitem, index) => {
                                    if (qveitem[1].cat_id == item[1].parent_id) {
                                        //   console.log(qveitem)
                                        //     console.log(item)
                                        setExactParent(qveitem)
                                        setItself(item)
                                    }
                                })
                            })
                    }

                })
                //   setApi(data.menu[338].content_id)




                // console.log('data.menu[1] api' , data.menu[338].content_id)
                //  console.log('menu', menu)


                //  console.log('object entries', Object.entries(menu))
                // console.log(istoriebi)


            });
    }, [])



    /* sertifikaris dadasturebebi */
    const [shipCertificateDataError, setShipCertificateDataError] = useState(false)
    const [shipCertificateData, setShipCertificateData] = useState('')
    const [loader1, setLoader1] = useState(false)
    const [certificateFormData, setCertificateFormData] = useState({
        docnr: '',
        seafarernr: '',

    });

    const handleCertificateChange = (e) => {
        const { name, value } = e.target;
        setCertificateFormData({
            ...certificateFormData,
            [name]: value,
        });
    };

    const handleCertificateSubmit = async (e) => {
        e.preventDefault();
        setLoader2(true)
        const queryString = new URLSearchParams(certificateFormData).toString();
        // console.log(queryString)
        try {
            //docnr=GEO014216&seafarernr=1401007483
            const response = await fetch(`${config.baseAPI}/api/sertificate.php?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {

                const data = await response.json();

                setShipCertificateData(data?.txt_content);

                if (data) {
                    if (data.txt_content == '') {
                        setShipCertificateDataError(true)
                    } else {
                        setShipCertificateDataError(false)
                    }
                }
            } else {
                // Handle error response
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
        setLoader2(false)
    };



    ///////////////////////

    const [shipRegistrationDataError, setshipRegistrationDataError] = useState(0)

    const [registrationTracking, setRegistrationTracking] = useState('')
    const [registrationCERN, setRegistrationCERN] = useState('')
    const [registrationDate, setregistrationDate] = useState('')
    const [loader2, setLoader2] = useState(false)


    const handleregistrationSubmit = async (e) => {
        e.preventDefault();
        setLoader2(true)
        let formattedDate = '';
        if (registrationDate != '') {
            // console.log('inside')
            formattedDate = registrationDate.format().split(['T'])[0]
        }


        try {
            let link = `${config.baseAPI}/api/sertificate.php?action=verify_certificate&tracking=${registrationTracking}&cer_number=${registrationCERN}&date=${formattedDate}`
            // console.log(link)
            const response = await fetch(link, {
                method: 'GET',
                headers: {
                    'Accept': 'application/pdf',
                },
            });


            if (response.ok) {
                const data = await response.blob();
                // console.log(data)
                if (data.size == 1) {
                    const response2 = await fetch(link, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/pdf',
                        },
                    });
                    if (response2.ok) {
                        const data2 = await response2.json()
                        //  console.log(data2)
                        setshipRegistrationDataError(data2)
                    }




                } else {
                    setshipRegistrationDataError(0)
                    var fileURL = URL.createObjectURL(data);
                    window.open(fileURL);
                }



            } else {
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
        }
        setLoader2(false)
    };

    const [medicalInput, setMedicalInput] = useState('')
    const [medicalError, setMedicalError] = useState(false)
    const [medicalData, setMedicalData] = useState({})

    const handleMedicalInputChange = (event) => {
        // Get the input value from the event
        const inputValue = event.target.value;

        // Use a regular expression to keep only letters
        const filteredInput = inputValue.replace(/\D/g, '');

        // Update the state with the filtered input
        setMedicalInput(filteredInput);
    };
    const handleMedicineSubmit = async (e) => {
        e.preventDefault();
        setLoader1(true)
        try {
            //docnr=GEO014216&seafarernr=1401007483
            const response = await fetch(`${config.baseAPI}/api/sertificate.php?action=checkSeafarer&seafarerNumber=${medicalInput}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/pdf',
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data.error) { setMedicalError(true) } else { setMedicalError(false) }
                //  console.log(data)
                setMedicalData(data)
            } else {
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
        }
        setLoader1(false)
    };





    const medicalFormUI = <>
        <div>
            <form action="" id="medical-verification">
                <h3 className="sub-title">{content.medical_certificate_verification}</h3>
                <input
                    type="text"
                    value={medicalInput}
                    onChange={handleMedicalInputChange}
                    className={medicalError ? 'error' : ""}
                />
                <button className='check--btn' onClick={handleMedicineSubmit}>{content.verification}</button>
            </form>
            <div className="mt-3">
                {medicalData.FirstName ?
                    <div>
                        <ul className='ps-2'>
                            <li>
                                <strong>{content.seafarer_name}:</strong> <span>{isGeorgian ? medicalData?.FirstName : medicalData?.FirstNameEn}</span>
                            </li>
                            <li>
                                <strong>{content.seafarer_surname}:</strong> <span>{isGeorgian ? medicalData?.LastName : medicalData?.LastNameEn}</span>
                            </li>
                            <li>
                                <strong>{content.seafarer_PIN}:</strong> <span>{medicalData?.PrivateNumber}</span>
                            </li>
                            <li>
                                <strong>{content.seafarer_number}:</strong> <span>{medicalData?.SeafarerNumber}</span>
                            </li>
                            <li>
                                <strong className='redy'>{content.blank_number}:</strong> <span>{medicalData?.BlankNumber}</span>
                            </li>
                            <li>
                                <strong>{content.issuer}:</strong> <span>{isGeorgian ? medicalData?.IssuingAuthority : medicalData?.IssuingAuthorityEn}</span>
                            </li>
                            <li>
                                <strong className='redy'>{content.fit_for_duty}:</strong> <span>{medicalData?.IsFitForDuty ? content.yes : content.no}</span>
                            </li>
                            <li>
                                <strong>{content.issue_date}:</strong> <span>{medicalData?.DateOfIssue.split('T')[0]}</span>
                            </li>
                            <li>
                                <strong>{content.expiry_date}:</strong> <span>{medicalData?.ExpiryDate.split('T')[0]}</span>
                            </li>
                        </ul>
                    </div>
                    : ""}
            </div>
            <div className="d-flex justify-content-center mt-3" style={{ minHeight: '35px' }}>
                {loader1 ? <Spinner animation="grow" /> : ""}
            </div>
        </div>
    </>



    return (
        <motion.div
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='contentPage'
        >
            <BreadCrumb
                parent={exactParent}
                itself={itself}
                isContentPage={true}
            />
            <div className="container">
                <h1 className="contentPage--header">
                    {isGeorgian ? itself[1]?.name_geo : itself[1]?.name_eng}
                </h1>
                <div className="d-flex gap-30 flex-lg-row flex-column ">

                    <div className={`content ${id == 75 ? 'mw-100 w-100' : ""}`} >

                        <div dangerouslySetInnerHTML={{ __html: istoriebi.length ? (isGeorgian ? istoriebi[0]?.geo?.content?.replaceAll('../', `${config.baseAPI}/`) : istoriebi[0]?.eng?.content?.replaceAll('../', `${config.baseAPI}/`)) : "" }}>

                        </div>
                        <div className={id == 98 ? "" : 'd-none'}> {/*ship registration form */}
                            {shipRegistrationDataError == 1 ? <div className='my-2 redy'>გთხოვთ მიუთითოთ IMO ნომერი ან გაცემის თარიღი</div> : ""}
                            {shipRegistrationDataError == 2 ? <div className='my-2 redy'>მოწმობის უნიკალური ნომერის შეყვანა აუცილებელია</div> : ""}
                            {shipRegistrationDataError == 3 ? <div className='my-2 redy'>{content.document_not_found}</div> : ""}
                            <form action="" id="ship-registration" style={shipRegistrationDataError ? { borderColor: "#ff2e34" } : { borderColor: '#D2D2D2' }}>
                                <div>
                                    <p >{content.tracking_id}</p>
                                    <input type="text" onChange={(e) => setRegistrationTracking(e.target.value)} />
                                </div>
                                <div>
                                    <p >{content.IMO_number}</p>
                                    <input type="text" onChange={(e) => setRegistrationCERN(e.target.value)} />
                                </div>
                                <div>
                                    <p >{content.issue_date}</p>
                                    <DemoItem>
                                        <DatePicker onChange={setregistrationDate} value={registrationDate} />
                                    </DemoItem>
                                </div>
                                <button className='check--btn' onClick={handleregistrationSubmit}>{content.verification}</button>
                            </form>
                            <div className='mt-3'>
                                <p>
                                    საზღვაო ტრანსპორტის სააგენტოს <b>2024 წლის 1 თებერვლის No: 4/CIRC/FSI</b>  ცირკულარის შესაბამისად, ელექტრონული მოწმობები და დოკუმენტები, რომლებიც გაცემულია საქართველოს დროშის ქვეშ მცურავი გემის მიმართ,   მოწმობაზე  ან დოკუმენტზე დატანილი სწრაფი რეაგირების კოდით (QR-ქვედა, მოწმობის მარჯვენა ნაწილში) და უნიკალური საიდენტიფიკაციო  ნომერით (<b>UTN – MTAcertID-000000</b>  მოწმობის ქვედა, შუა ნაწილში), შეიძლება გადამოწმდეს ქვემოთ მოცემული ვერიფიკაციის გზით.
                                </p>

                                <div className='d-flex justify-content-center my-3'>
                                    <p style={{ width: '90%', border: "1px solid #D2D2D2" }}></p>
                                </div>
                                <p>
                                    As per Electronic Documents and Certificates CIRC <b>No: 5/CIRC/FSI dated February 16<sup>st</sup> , 2024</b> electronic documents and certificates issued to Georgian flagged vessels with a document or certificate number or that have a Quick Response (QR) Code in the lower right corner and a Unique Tracking Number ( <b>UTN – MTAcertID-000000</b> ) in the middle, may be verified below.
                                </p>
                            </div>
                            <div className="d-flex justify-content-center mt-3" style={{ minHeight: "35px" }}>
                                {loader2 ? <Spinner animation="grow" /> : ""}
                            </div>
                        </div>

                        <div className={id == 29 ? "mt-4" : 'd-none mt-4'}> {/*seafarer sertificate form   */}
                            <h1 className="sub-title" dangerouslySetInnerHTML={{ __html: content.crew_certificate_verification }}></h1>
                            <form action="" id='certificate-verification' onSubmit={handleCertificateSubmit}>
                                <div className="input--container">
                                    <div>   <input type="text" placeholder={content.first_name} name="name" onChange={handleCertificateChange} /></div>
                                    <div>   <input type="text" placeholder={content.last_name} name="lname" onChange={handleCertificateChange} /></div>
                                    <div className={`required ${shipCertificateDataError ? "error" : ""}`}>   <input type="text" placeholder={content.seafarer_number} name="seafarernr" onChange={handleCertificateChange} /></div>
                                    <div className={`required ${shipCertificateDataError ? "error" : ""}`}>  <input type="text" placeholder={content.document_number} name="docnr" onChange={handleCertificateChange} /></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3 flex-lg-row flex-column">
                                    <p><span className='asterisk'>*</span>  <span>{content.required_fields}</span></p>
                                    <button className='check--btn' type='submit'>{content.verification}</button>
                                </div>
                            </form>
                            {shipCertificateData ?
                                <div className="table-responsive">
                                    <div className='mt-3' dangerouslySetInnerHTML={{ __html: shipCertificateData.replaceAll('images/verprinter.png', 'assets/images/verprinter.png').replaceAll('images/versave.png', 'assets/images/versave.png') }}>

                                    </div>
                                </div> : ""}

                            <div className="d-flex justify-content-center mt-3" style={{ minHeight: '35px' }}>
                                {loader2 ? <Spinner animation="grow" /> : ""}
                            </div>
                        </div>

                    </div>





                    {id == 29 ? //medical form
                        medicalFormUI : <Accord
                            entireMenu={entireMenu}
                            itself={itself[1]?.level == 3 ? exactParent : itself}
                            innerMenu={itself}
                            contentId={id}
                        />
                    }


                </div>
            </div>




        </motion.div>
    )
}

export default ContentPageApi