import React from 'react'
import { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { useNavigate, Link, useParams } from 'react-router-dom'
import BreadCrumb from './atoms/BreadCrumb'
import Accord from './atoms/Accord'
import { LanguageContext } from '../components/contexts/LanguageContext';
import config from '../config/conf.json'
function News1() {
    const { language, changeLanguage , isGeorgian, content } = useContext(LanguageContext);
    const { id } = useParams();
    // const [news, setNews] = useState(ArrayOfObject[id])
    const [moreNews, setMoreNews] = useState({})

    //  const [news,setNews] = useState({})
    const [exactNews, setExactNews] = useState({})
    
   

    useEffect(() => {
        const timeoutId = setTimeout(() => {
        let link;
        if (isGeorgian == true) {
            link = `${config.baseAPI}/api/news.php?lang=geo`;
          } else {
            link = `${config.baseAPI}/api/news.php?lang=eng`;
          }
        
        fetch(link)
            .then((response) => response.json())
            .then((data) => {
                
                console.log(data)
               
                console.log(data.მთავარი[id])
                if(data.მთავარი[id]){
                    setExactNews(data.მთავარი[id])
                }
                if(data.სიახლეები[id]){
                    console.log('here')
                    setExactNews(data.სიახლეები[id])
                }
               
                setMoreNews(data.მთავარი)
                // console.log('data ID',data.სიახლეები[id].geo)

                // console.log(data.სიახლეები[id].geo.text)

                // console.log(data)
                // console.log('news', news)

                // console.log('object keys menu', Object.keys(menu))
                // console.log('object entries news', Object.entries(data))


            });

        }, 1000); // Replace 1000 with the desired timeout duration in milliseconds

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);

    }, [isGeorgian])

    return (
        <motion.div
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='contentPage'
        >
            <BreadCrumb
                isContentPage={false}
                pageName={content.news}
                parent={''}
                itself={''}
            />
            <div className="container">
                <h1 className="contentPage--header">
                    {isGeorgian ?  exactNews?.geo?.title : exactNews?.eng?.title}
                </h1>
                <div className="d-flex gap-30 flex-lg-row flex-column ">
                    <div className='content' dangerouslySetInnerHTML={{ __html: isGeorgian ? exactNews?.geo?.text.replaceAll('../', `${config.baseAPI}/`) : exactNews?.eng?.text?.replaceAll('../', `${config.baseAPI}/`) }}>

                    </div>


                </div>
            </div>



        </motion.div>
    )
}

export default News1