import React from 'react'
import { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { useNavigate, Link, useParams } from 'react-router-dom'
import BreadCrumb from './atoms/BreadCrumb'
import config from '../config/conf.json'
import Accord from './atoms/Accord'
import { LanguageContext } from '../components/contexts/LanguageContext';
function Search() {
    const { language, changeLanguage, isGeorgian, content } = useContext(LanguageContext);
    const { id } = useParams();
    
    //console.log(id)


    const [contentAPI, setContentAPI] = useState([])
    const [news, setNews] = useState([])
    const [projects, setProjects] = useState({})
   let counter = 0;
    const removeHtmlTags = (html) => {
        if(html){
            return html.replace(/<\/?[^>]+(>|$)/g, '');
        } else {
            return ''
        }
        
      };
    useEffect(() => {
        const apiLink = `${config.baseAPI}/api/get_content.php?search=${id}`
       // console.log(apiLink)
        fetch(apiLink)
            .then((res) => res.json())
            .then((dat) => {
               // console.log(dat)
                setContentAPI(dat)

            })
           
    }, [id])

    useEffect(() => {
        const apiLink = `${config.baseAPI}/api/news.php?search=${id}&lang=geo`
      //  console.log(apiLink)
        fetch(apiLink)
            .then((res) => res.json())
            .then((dat) => {
              //  console.log(dat)
             //   console.log(dat.სიახლეები)
              //  console.log(Object.entries(dat.სიახლეები).length)
                if (dat.სიახლეები && dat.სიახლეები.length !== 0) {
                    setNews(Object.entries(dat.სიახლეები));
                }
                if (dat.პროექტები && dat.პროექტები.length !== 0) {
                    setProjects(Object.entries(dat.პროექტები));
                }
                 
               
            })
          
    }, [id])
 
    return (
        <motion.div
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='contentPage'
        >
            <BreadCrumb
                isContentPage={false}
                pageName={content.search}
                parent={''}
                itself={''}
            />
            <div className="container">
                <h1 className="contentPage--header">
                    {content.search}
                </h1>
                <div className="d-flex gap-30 flex-lg-row flex-column ">
                    <div className="search--content">
                    {(news.length && news.length !== 0) ?
                            news.map((con,i) => {
                              //  console.log(con)
                               counter++;
                                let contentWithRemovedTags = removeHtmlTags(con[1].geo.text).replaceAll('nbsp', '').replaceAll('MSD&', '').replaceAll('&;', '')
                                const index = contentWithRemovedTags.indexOf(id);
                                const start = Math.max(0, index - 40);
                                const end = Math.min(contentWithRemovedTags.length, index + id.length + 50);
                                const truncatedContent = contentWithRemovedTags.substring(start, end).replaceAll(`${id}`, `<b>${id}</b>`);
                               
                              //  console.log(truncatedContent)
                                return <>
                                <p className='search--item__header'>
                                    <b>   {content.main} {'>'} {content.news}</b>
                                </p>
                                <Link key={con[1].geo.rec_id} dangerouslySetInnerHTML={{ __html: `${counter} ... ${truncatedContent}...` }} className='search--item mb-3 d-block' to={`/${language}/news/${con[1].geo.rec_id}`}>
                                   
                                </Link>
                                       </>
                            })


                            : ""}
                               {(projects.length && projects.length !== 0) ?
                            projects.map((con,i) => {
                              //  console.log(con)
                                counter++;
                                let contentWithRemovedTags = removeHtmlTags(con[1].geo.text).replaceAll('nbsp', '').replaceAll('MSD&', '').replaceAll('&;', '')
                                const index = contentWithRemovedTags.indexOf(id);
                                const start = Math.max(0, index - 40);
                                const end = Math.min(contentWithRemovedTags.length, index + id.length + 50);
                                const truncatedContent = contentWithRemovedTags.substring(start, end).replaceAll(`${id}`, `<b>${id}</b>`);
                               
                              //  console.log(truncatedContent)
                                return <>
                                <p className='search--item__header'>
                                    <b>   {content.main} {'>'} {content.kontenturi}</b>
                                </p>
                                <Link key={con[1].geo.rec_id} dangerouslySetInnerHTML={{ __html: `${counter} ... ${truncatedContent}...` }} className='search--item mb-3 d-block' to={`/${language}/projects/${con[1].geo.rec_id}`}>
                                   
                                </Link>
                                       </>
                            })


                            : ""}
                        
                        
                        
                        
                        {contentAPI.length ?
                            contentAPI.map((con,i) => {
                              //  console.log(con)
                                counter++;
                                let contentWithRemovedTags = removeHtmlTags(con?.geo?.content).replaceAll('nbsp', '').replaceAll('MSD&', '').replaceAll('&;', '')
                                const index = contentWithRemovedTags.indexOf(id);
                                const start = Math.max(0, index - 40);
                                const end = Math.min(contentWithRemovedTags.length, index + id.length + 50);
                                const truncatedContent = contentWithRemovedTags.substring(start, end).replaceAll(`${id}`, `<b>${id}</b>`);
                               
                              //  console.log(truncatedContent)
                                return <>
                                <p className='search--item__header'>
                                    <b>  {content.main} {'>'} {content.kontenturi}</b>
                                </p>
                                <Link key={con?.geo?.rec_id} dangerouslySetInnerHTML={{ __html: `${counter} ... ${truncatedContent}...` }} className='search--item mb-3 d-block' to={`/${language}/content/${con?.geo?.rec_id}`}>
                                   
                                </Link>
                                       </>
                            })


                            : ""}
                            {(news.length && contentAPI.length && projects.length) ? "" : "ვერ მოიძებნა"}
                    </div>


                </div>
            </div>



        </motion.div>
    )
}

export default Search