import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../components/contexts/LanguageContext';
import Spinner from 'react-bootstrap/Spinner';
import config from '../config/conf.json';
import ReactPaginate from 'react-paginate';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function News() {
  const { language, isGeorgian, content } = useContext(LanguageContext);
  const [lastNews, setLastNews] = useState({});
  const [latest3News, setLatest3News] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 12;

  useEffect(() => {
    const timeoutId = setTimeout(() => {

      let link;
      if (isGeorgian === true) {
        link = `${config.baseAPI}/api/news.php?lang=geo`;
      } else {
        link = `${config.baseAPI}/api/news.php?lang=eng`;
      }

      fetch(link)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          // Get all news items
          const allNews = Object.values(data.მთავარი);

          // Set the last news item
          setLastNews(allNews[allNews.length - 1]);

          // Set all news items
          setLatest3News(allNews.reverse()); // Reversed to have latest news first

        });
    }, 1000); // Replace 1000 with the desired timeout duration in milliseconds

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [isGeorgian, language]);

  // Logic to handle pagination
  const offset = currentPage * itemsPerPage;
  const currentItems = latest3News.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(latest3News.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='container'>
      <section className="news--section">
        <h1 className="title mb-30">{content.news}</h1>
        <div className='news--container mt-0 w-100'>
          <div className="news--container--seperate">
            {currentItems.length ? (
              currentItems.map((news) => {
                return (
                  <Link key={isGeorgian ? news.geo.rec_id : news.eng.rec_id} className="small--news" to={`/${language}/news/${isGeorgian ? news.geo.rec_id : news.eng.rec_id}`}>
                    <img src={`${config.baseAPI}/${isGeorgian ? news?.geo?.img : news?.eng?.img}`} alt="" />
                    <p className="small--news__time">
                      <span>{isGeorgian ? news?.geo?.date.split(' ')[0] : news?.eng?.date.split(' ')[0]}</span>
                      {isGeorgian ? news?.geo?.date.split(' ').slice(1).join(' ') : news?.eng?.date.split(' ').slice(1).join(' ')}
                    </p>
                    <h2 className="title">{isGeorgian ? news?.geo?.title : news?.eng?.title}</h2>
                    <p className="big--news__more">{content.learn_more}</p>
                  </Link>
                );
              })
            ) : (
              <div className='news-spinner--container'><Spinner animation="grow" color='#2E6383'/></div>
            )}
          </div>
        </div>
        <ReactPaginate
           previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
           nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </section>
    </div>
  );
}
