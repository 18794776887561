import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, Link, useParams } from 'react-router-dom';
import BreadCrumb from './atoms/BreadCrumb';
import Accord from './atoms/Accord';
import FooterMenuAccord from './atoms/FooterMenuAccord';
import { LanguageContext } from '../components/contexts/LanguageContext';
import config from '../config/conf.json';
import Spinner from 'react-bootstrap/Spinner';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function NMSW() {
    const { language, changeLanguage, isGeorgian, content } = useContext(LanguageContext);
    const navigate = useNavigate();
    const id = 252;
    console.log(id);
    const [api, setApi] = useState({});
    const [istoriebi, setIstoriebi] = useState([]);
    const [fmenuName, setFmenuName] = useState('');
    const [exactParent, setExactParent] = useState([]);
    const [itself, setItself] = useState([]);
    const [entireMenu, setEntireMenu] = useState([]);

    useLayoutEffect(() => {
        const link = `${config.baseAPI}/api/site_menu.php`;
        fetch(link)
            .then((response) => response.json())
            .then((data) => {
                setEntireMenu(Object.entries(data.menu));
                Object.entries(data.menu).forEach((item) => {
                    if (item[1].cat_id == id) {
                        setApi(item[1].content_id);
                        setItself(item);

                        const apiLink = `${config.baseAPI}/api/get_content.php?content_id=${item[1].content_id[0]}`;
                        fetch(apiLink)
                            .then((res) => res.json())
                            .then((dat) => {
                                setIstoriebi(dat);
                                Object.entries(data.menu).forEach((qveitem) => {
                                    if (qveitem[1].cat_id == item[1].parent_id) {
                                        setExactParent(qveitem);
                                    }
                                });
                            });
                    }
                });
            });
    }, [id]);

    const hasSubmenus = entireMenu.some(menu => menu[1].parent_id == id);
    const isSubmenu = itself[1]?.parent_id != 1;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='contentPage'
        >
            <BreadCrumb
                isContentPage={true}
                pageName={isGeorgian ? itself[1]?.name_geo : itself[1]?.name_eng}
                parent={exactParent}
                itself={itself}
            />
            <div className="container">
                <h1 className="contentPage--header">
                    {isGeorgian ? itself[1]?.name_geo : itself[1]?.name_eng}
                </h1>
                <div className="d-flex gap-30 flex-lg-row flex-column">
                    <div className={`content ${id == 75 ? 'mw-100 w-100' : ""}`}>
                        <div dangerouslySetInnerHTML={{
                            __html: istoriebi.length
                                ? (isGeorgian ? istoriebi[0]?.geo?.content?.replaceAll('../', `${config.baseAPI}/`)
                                    : istoriebi[0]?.eng?.content?.replaceAll('../', `${config.baseAPI}/`))
                                : ""
                        }}>
                        </div>
                    </div>

                    {(hasSubmenus || isSubmenu) && (
                        <FooterMenuAccord
                            entireMenu={entireMenu}
                            itself={itself[1]?.level == 3 ? exactParent : itself}
                            innerMenu={itself}
                            contentId={id}
                        />
                    )}
                </div>
            </div>
        </motion.div>
    );
}

export default NMSW;
