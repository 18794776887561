import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import BreadCrumb from './atoms/BreadCrumb'
import { LanguageContext } from '../components/contexts/LanguageContext';
import config from '../config/conf.json'
function Contact() {
    const { language, changeLanguage , isGeorgian, content } = useContext(LanguageContext);
    const [contacts, setContacts] = useState([])
    console.log(isGeorgian)
    useEffect(() => {
        let link;
        if(isGeorgian){
            link = `${config.baseAPI}/api/contacts_info.php?lang=geo`
        } else{
            link = `${config.baseAPI}/api/contacts_info.php?lang=eng`
        }
        
        fetch(link)
            .then((response) => response.json())
            .then((data) => {
               
                setContacts(data)

            });

    }, [isGeorgian])
    return (
        <motion.div
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='contact--page'>
            <BreadCrumb
                isContentPage={false}
                parent={''}
                itself={''}
                pageName={content.contact}
            />
            <div className="container">
                <h1 className="title">{content.contact}</h1>
                <div className="d-flex flex-lg-row flex-column">
                    <div className="col-md-8 pe-lg-3">
                        <div className="contacts--container">
                            {contacts.length ?

                                contacts.map((contact) => {
                                    return <div className="contact--item">
                                                <div className='col-md-7'>
                                                    <h4 className="contact--name">{contact.name}</h4>
                                                    <p className="contact--address">{contact.address}</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="contact--email">
                                                        <a href="mailto:info@mta.gov.ge"> <FaEnvelope />  {contact.email}</a>
                                                    </div>
                                                    <div className="contact--mobile"> <p> <FaPhoneAlt /> <span>{contact.phone}</span> </p></div>
                                                </div>
                                            </div>
                                })

                                : ""}
                           
                            {/* <div className="contact--item">
                                <div className='col-md-7'>
                                    <h4 className="contact--name">მეზღვაურთა დეპარატემენტის ცხელი ხაზი</h4>
                                    <p className="contact--address"></p>
                                </div>
                                <div className='col-md-4'>
                                    <div className="contact--email">
                                        <a href="mailto:info@mta.gov.ge"> <FaEnvelope />  info@mta.gov.ge</a>
                                    </div>
                                    <div className="contact--mobile"> <p> <FaPhoneAlt /> <span>+995 (422) 274925 / 2000 /2002</span></p></div>
                                </div>
                            </div>
                            <div className="contact--item">
                                <div className='col-md-7'>
                                    <h4 className="contact--name">სააგენტოს დირექტორი</h4>
                                    <p className="contact--address"></p>
                                </div>
                                <div className='col-md-4'>
                                    <div className="contact--email">
                                        <a href="mailto:info@mta.gov.ge"> <FaEnvelope />  info@mta.gov.ge</a>
                                    </div>
                                    <div className="contact--mobile"> <p> <FaPhoneAlt /> <span>+995 (422) 274925 / 2000 /2002</span></p></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-4 ps-lg-3">
                        <form action="">
                            <h3 className="sub-title">
                               {content.contact_message}
                            </h3>
                            <input type="text" placeholder=  {content.contact_name} />
                            <input type="text" placeholder=  {content.contact_gmail} />
                            <input type="text" placeholder=  {content.contact_title} />
                            <textarea name="" id="" cols="30" rows="7" placeholder= {content.contact_description}></textarea>
                            <button>  {content.contact_send}</button>
                        </form>
                    </div>

                </div>
            </div>
        </motion.div>
    )
}

export default Contact