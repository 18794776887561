import React, { useState, useRef, useEffect, useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import { LanguageContext } from '../../components/contexts/LanguageContext';
function Accord(props) {
  // console.log(props)
  const { language, changeLanguage, isGeorgian } = useContext(LanguageContext);
  const emptyBodyRef = useRef([]);


  useEffect(() => {
    // Check if accordion body has no children
    emptyBodyRef.current.forEach((item) => {

      if (!item.childNodes[1].querySelector('.accordion__body--link')) {
        // Add your desired class to the accordion-item
        item.classList.add('no-body');
      }
    });
  },);

  //console.log(props.innerMenu)


  return (
    <div style={{ width: '317px', flexShrink: '0' }} className={props.contentId == 75 ? 'd-none' : ""}>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        {props.entireMenu.map((menu, i) => {
          if (menu[1].parent_id == props.itself[1]?.parent_id) {
            return <Accordion.Item eventKey={menu[1].cat_id == props.itself[1].cat_id ? '0' : i + 1} ref={(el) => (emptyBodyRef.current[i] = el)}>
              <Accordion.Header><Link to={`/${language}/content/${menu[1].cat_id}`} className={menu[1].cat_id == props.itself[1].cat_id ? 'accordion--active' : ""}>{isGeorgian ? menu[1].name_geo : menu[1].name_eng}</Link> </Accordion.Header>
              <Accordion.Body>
                {props.entireMenu.map((qveMenu) => {
                  if (menu[1].cat_id == qveMenu[1].parent_id) {
                    return <Link className={`accordion__body--link ${qveMenu[1].cat_id == props.innerMenu[1].cat_id ? 'accordion--active' : ""}`} to={`/${language}/content/${qveMenu[1].cat_id}`}>{isGeorgian ? qveMenu[1].name_geo : qveMenu[1].name_eng}</Link>
                  }
                })}
              </Accordion.Body>
            </Accordion.Item>
          }
        })}


      </Accordion>
    </div>
  )
}

export default Accord