import React, {useContext} from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { LanguageContext } from '../components/contexts/LanguageContext';
// Import Swiper styles
import 'swiper/css';
function Footer() {
  const { language, changeLanguage , isGeorgian , content } = useContext(LanguageContext);
  return (
    <footer>
      <div className="container">
        <Swiper
          spaceBetween={20}
          loop={true}
          slidesPerView={window.innerWidth > 992? 5 : 2}
         
          
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <a href="http://gov.ge/" className="d-block" target='blank'>
              <img src={require('../assets/images/slide1.jpg')} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="http://maritimegeorgia.ge" className="d-block" target='blank'>
              <img src={require('../assets/images/slide2.jpg')} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="http://www.solidaroba.ge/" className="d-block" target='blank'>
              <img src={require('../assets/images/slide3.jpg')} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="http://www.imo.org" className="d-block" target='blank'>
              <img src={require('../assets/images/slide4.jpg')} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="http://www.imo.org" className="d-block" target='blank'>
              <img src={require('../assets/images/slide5.jpg')} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="http://www.solidaroba.ge/" className="d-block" target='blank'>
              <img src={require('../assets/images/slide3.jpg')} alt="" />
            </a>
          </SwiperSlide>

        </Swiper>
      </div>
      <div className="footer--bottom">
        <div className="container">
          <div className="d-flex justify-content-center flex-lg-row flex-column">
            <div>
              <p className="footer--title mb-4">{content.contact_us}</p>
              <p className='mb-3'>+995 (422) 274925 / 2000 /2002</p>
              {/* <p className='mb-3'>032 2 471 707</p> */}
              <p className='mb-4'>info@mta.gov.ge</p>
              <div className='d-flex gap-3'>
                <img src={require("../assets/images/gerrbi.svg").default} alt="" style={{ height: '50px' }} />
                <h3 className='mt-3 mb-0' dangerouslySetInnerHTML={{__html: content.mta}}>
                 
                </h3>
              </div>
              <div className="credits">
                © {content.all_rights_reserved} mta.gov.ge 2023
              </div>

            </div>
            <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.454947359231!2d41.649736576072684!3d41.64591147126817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40678623624bb15b%3A0x2eb53363bcfc431d!2sMaritime%20Transport%20Agency%20Of%20Georgia!5e0!3m2!1sen!2sge!4v1699617699949!5m2!1sen!2sge" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='address'></iframe>
              <p className='text-end'>Created by Proservice</p>
            </div>
          </div>
        </div>
      </div>


    </footer>
  )
}

export default Footer