import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import config from '../config/conf.json'
import { RxHamburgerMenu } from 'react-icons/rx'
import Modal from 'react-bootstrap/Modal';
import { LanguageContext } from '../components/contexts/LanguageContext';
function Header() {

  const { language, changeLanguage, isGeorgian, content } = useContext(LanguageContext);
  // console.log(content)
  const navigate = useNavigate()
  const location = useLocation();
  // console.log(location)
  const currentLocation = location.pathname;

  // Check if the current path is not the main page ("/")
  const isNotMainPage = currentLocation !== '/' && currentLocation !== '/ka' && currentLocation !== '/en';

  // Define inline styles conditionally
  const inlineStyles = isNotMainPage ?
    {
      // Your styles for paths other than the main page
      height: '243px',
      backgroundImage: 'url("./assets/images/Rectangle\ 116.png");',
      transition: "0.4s ease"
    } : {
      transition: "0.4s ease"
    };
  const inlineStylesForHeaderLandingBottom = isNotMainPage ?
    {
      // Your styles for paths other than the main page
      height: '133px',
      transition: "0.4s ease"

    } : {
      transition: "0.4s ease"
    };

  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }


  const [menu, setMenu] = useState([])
  useEffect(() => {
    const link = `${config.baseAPI}/api/site_menu1.php`
    fetch(link)
      .then((response) => response.json())
      .then((data) => {
        setMenu(Object.entries(data.menu))
        // console.log('data.menu',data.menu)
          console.log(data.menu)
        data.menu.map((men) => {
          if (men.parent_id == 5) {
            //  console.log(men)
          }
        })

        //  console.log('menu', menu)


        //  console.log('object entries', Object.entries(menu))


      });



  }, [])

  const [search, setSearch] = useState('')

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleToSearch = (e) => {

    if (!(search.trim() == '')) {
      navigate(`/ka/search/${search.trim()}`)

    }
  }
  const handleKeyPress = (event) => {

    if (event.key === 'Enter') {
      event.preventDefault();
      handleToSearch();
    }
  };
  //console.log(isGeorgian)
  return (
    <header>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{content.site_map}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-wrap gap-3 justify-content-between'>
            {menu.length ? menu.map((item, index) => {
              // console.log(item)
              if (!(item[1].cat_id == 90 || item[1].cat_id == 99 || item[1].cat_id == 11)) {
                if (item[1].level == 1) {
                  return <div className="col-md-6">
                    <div className="modal__nav--header">
                      <h3>
                        <Link to={`${language}/content/${item[1].href}`} onClick={() => setShow(false)}> {isGeorgian ? item[1].name_geo : item[1].name_eng}</Link>
                      </h3>
                    </div>
                    {menu.map((qveItem, qveIndex) => {
                      if (item[1].cat_id == qveItem[1].parent_id && qveItem[1].level == 2) {
                        return <p>
                          <Link to={`${language}/content/${qveItem[1].cat_id == 245 ? 254 : qveItem[1].cat_id}`} onClick={() => setShow(false)}>{isGeorgian ? qveItem[1].name_geo : qveItem[1].name_eng}</Link>
                          {menu.map((qveqveItem, qveqveIndex) => {
                            if (qveItem[1].cat_id == qveqveItem[1].parent_id) {
                              return <p>
                                <Link to={`${language}/content/${qveqveItem[1].cat_id}`} onClick={() => setShow(false)}>
                                  {isGeorgian ? qveqveItem[1].name_geo : qveqveItem[1].name_eng}
                                </Link>
                              </p>
                            }
                          })}
                        </p>
                      }
                    })}

                  </div>
                }
              }

            }) : "loading"}


          </div>
        </Modal.Body>
      </Modal>
      <div className="header--cont">
        <div className="header--landing" style={inlineStyles}>
          <div className="header--top">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-30">
                  <a href="https://www.facebook.com/maritimegeorgia" target='blank'><img src={require("../assets/images/facebook.svg").default} alt="" /></a>
                  <a href="https://www.linkedin.com/company/maritime-transport-agency-of-georgia/?trk=company_logo/" target='blank'><img src={require("../assets/images/linkedin.svg").default} alt="" /></a>
                  <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FMTAGEO" target='blank'><img src={require("../assets/images/twitter-bird.svg").default} alt="" /></a>
                  <a href="https://www.youtube.com/channel/UCnO9gjKS6ClMLZdSzsNUMeQ" target='blank'><img src={require("../assets/images/youtube.svg").default} alt="" /></a>
                </div>
                <div className="d-flex gap-35">
                  <form action="" className='d-flex gap-3 align-items-center'>
                    <input type="text" onChange={handleSearchChange} onKeyPress={handleKeyPress} />
                    <img src={require("../assets/images/Group 397.svg").default} alt="" onClick={handleToSearch} />
                  </form>
                  <div className="language-switcher">
                    <Link className={language == 'ka' ? "active" : ""} onClick={() => changeLanguage('ka')}> GE</Link>
                    <Link className={language == 'en' ? "active" : ""} onClick={() => changeLanguage('en')}>EN</Link>
                    {/* <Link className={language == 'ka'  ? "active" : ""} > GE</Link>
                    <Link className={language == 'en' ? "active" : ""} >EN</Link>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="header--nav">
            <div className="container">
              <ul className="d-flex justify-content-between mainRow">
                {menu.length ? menu.map((item, index) => {
                  // console.log(item)
                  if (!(item[1].cat_id == 90 || item[1].cat_id == 99 || item[1].cat_id == 11)) {
                    if (item[1].level == 1) {
                      //  console.log(item[1].cat_id)
                      return <li>
                        <Link to={`${language}/content/${item[1].href}`}>{isGeorgian ? item[1].name_geo : item[1].name_eng}</Link>
                        <ul className="sub--header">
                          {menu.map((qveItem, qveIndex) => {
                            if (item[1].cat_id == qveItem[1].parent_id && qveItem[1].level == 2) {
                              return <li>
                              <Link to={`${language}/content/${qveItem[1].cat_id == 245 ? 254 : qveItem[1].cat_id}`}>{isGeorgian ? qveItem[1].name_geo : qveItem[1].name_eng}</Link>
                                <ul className="subsub--header">
                                  {menu.map((qveqveItem, qveqveIndex) => {
                                    if (qveItem[1].cat_id == qveqveItem[1].parent_id) {
                                      return <li>
                                        <Link to={`${language}/content/${qveqveItem[1].cat_id}`}>
                                          {isGeorgian ? qveqveItem[1].name_geo : qveqveItem[1].name_eng}
                                        </Link>
                                      </li>
                                    }
                                  })}
                                </ul>
                              </li>
                            }
                          })}
                        </ul>
                      </li>
                    }
                  }


                }) : "loading"}
                {/* <li>
                  <Link to={'content'}>ჩვენს შესახებ</Link>
                </li>
                <li>
                  <Link>მეზღვაურები</Link>
                  <ul className="sub--header">
                    <li><Link>ინვოისი</Link> </li>
                    <li><Link>სერთიფიცირების პროცედურები</Link> </li>
                    <li><Link>განაცხადის ფორმები</Link> </li>
                  </ul>
                </li>
                <li>
                  <Link>ნავსადგურები</Link>
                  <ul className="sub--header">
                    <li><Link>ინვოისი</Link> </li>
                    <li>
                      <Link>სერთიფიცირების პროცედურები</Link>
                      <ul className="subsub--header">
                        <li><Link>ინვოისი</Link> </li>
                        <li><Link>სერთიფიცირების პროცედურები</Link> </li>
                        <li><Link>განაცხადის ფორმები</Link> </li>
                      </ul>
                    </li>
                    <li><Link>განაცხადის ფორმები</Link> </li>
                  </ul>
                </li>
                <li>
                  <Link>გემები</Link>
                </li>
                <li>
                  <Link>ძებნა-გადარჩენა</Link>
                </li>
                <li>
                  <Link>კანონმდებლობა</Link>
                </li>
                <li>
                  <Link>საერთაშორისო ურთიერთობები</Link>
                </li>
                <li>
                  <Link>საჯარო ინფრორმაცია</Link>
                </li>
                <li>
                  <Link>კითხვები</Link>
                </li> */}
              </ul>
            </div>
          </nav>
          <div className="header--landing__bottom" style={inlineStylesForHeaderLandingBottom}>
            <div className="container">
              <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                <Link className=" gap-3 align-items-end" style={{ display: 'flex' }} to={`${language}`}>
                  <img src={require("../assets/images/gerrbi.svg").default} alt="" style={{ height: '72px' }} />
                  <h1 dangerouslySetInnerHTML={{ __html: content.mta }}>

                  </h1>
                </Link>
                <div className='d-flex align-items-center gap-4'>
                  <Link className='contact--link' to={`${language}/content/9`}>{content.contact}</Link>
                  <img src={require("../assets/images/menu.svg").default} alt="" onClick={() => handleShow()} className='modal--button' />
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header